<template>
        <div>
            <div class="order-main">
              <img src="@/assets/image/icon_txcg.png" alt="">
            </div>
            <div style="text-align:center;margin-bottom:20px;font-size:35px">下單成功</div>
            <div class="order-foot">
              <van-button type="danger" size="large" style="border-radius:44px;border:none;font-size:30px;height:50px;width:300px;background: linear-gradient(90deg, #64B046, #AEE845);" @click="back">查看訂單</van-button>
            </div>
        </div>
</template>
<script>
export default {
    methods: {
        back() {
            this.$router.push({path:'/order'})
        }
    }
}
</script>
<style scoped>
.order-main {
  height: 450px;
  position: relative;
}
.order-main img {
  width: 300px;
  height: 300px;
  display: block;
  position: relative;
  top: 20%;
  left:30%
}
.order-foot {
  padding: 30px 28px;
  text-align: center;
}
</style>